import React from 'react';

import { AccountInfo, AccountInfoBankUS } from '@src/types';

export const COLUMNS = [
  { title: 'Rail Type', dataIndex: 'rail_type' },
  { title: 'Country', dataIndex: 'country' },
  { title: 'Rail', dataIndex: 'rail' },
  { title: 'Currency', dataIndex: 'currency' },
  { title: 'Name', dataIndex: 'name' },
  { title: 'Account Number', dataIndex: 'account_number' },
  {
    title: 'Info',
    dataIndex: 'info',
    render: (info: AccountInfo) => {
      if (!info || !info.data) {
        return 'N/A';
      }

      if (info.type === 'BANK_US') {
        const bankInfo = info.data as AccountInfoBankUS;
        return React.createElement(
          'div',
          {},
          React.createElement(
            'div',
            {},
            React.createElement('strong', {}, 'Account Number: '),
            bankInfo.account_number
          ),
          React.createElement('div', {}, React.createElement('strong', {}, 'Routing Number: '), bankInfo.routing_number)
        );
      }

      return 'Unknown Info Type';
    },
  },
  { title: 'Key ID', dataIndex: 'key_id' },
  { title: 'ID', dataIndex: 'id' },
  {
    title: 'Created at',
    dataIndex: 'created_at',
    render: (created_at: string) => {
      return new Date(created_at).toDateString();
    },
  },
  {
    title: 'Updated at',
    dataIndex: 'updated_at',
    render: (updated_at: string) => {
      return new Date(updated_at).toDateString();
    },
  },
];

export const PAGE_SIZE = 10;
