import { Card, Tag, Typography } from 'antd';

import { AdyenCapability } from '@src/types';

const { Title } = Typography;

type Props = {
  title: string;
  capability: AdyenCapability;
};

const AdyenCapabilityInfo = ({ title, capability }: Props) => (
  <Card size="small" bordered={false} className="bg-gray-900 mb-2 p-3 rounded-lg shadow-md">
    <Title level={5} className="text-white">
      {title}
    </Title>
    <div className="flex flex-wrap gap-2">
      <Tag color={capability.enabled ? 'green' : 'red'}>{capability.enabled ? 'Enabled' : 'Disabled'}</Tag>
      <Tag color={capability.allowed ? 'green' : 'red'}>{capability.allowed ? 'Allowed' : 'Not Allowed'}</Tag>
      <Tag color={capability.requested ? 'blue' : 'gray'}>{capability.requested ? 'Requested' : 'Not Requested'}</Tag>
      <Tag color={capability.verificationStatus === 'valid' ? 'green' : 'orange'}>{capability.verificationStatus}</Tag>
    </div>

    {capability.transferInstruments && capability.transferInstruments.length > 0 && (
      <div className="mt-3">
        <Title level={5} className="text-white">
          Transfer Instruments
        </Title>
        {capability.transferInstruments.map((instrument, index) => (
          <Card key={index} size="small" className="bg-gray-800 p-2 mt-2 rounded">
            <Tag color={instrument.enabled ? 'green' : 'red'}>{instrument.enabled ? 'Enabled' : 'Disabled'}</Tag>
            <Tag color={instrument.allowed ? 'green' : 'red'}>{instrument.allowed ? 'Allowed' : 'Not Allowed'}</Tag>
            <Tag color={instrument.requested ? 'blue' : 'gray'}>
              {instrument.requested ? 'Requested' : 'Not Requested'}
            </Tag>
            <Tag color={instrument.verificationStatus === 'valid' ? 'green' : 'orange'}>
              {instrument.verificationStatus}
            </Tag>
          </Card>
        ))}
      </div>
    )}
  </Card>
);

export default AdyenCapabilityInfo;
