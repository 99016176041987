export const COLUMNS = [
  { title: 'Name', dataIndex: 'business_name' },
  { title: 'Contact Name', dataIndex: 'first_name' },
  { title: 'Phone', dataIndex: 'phone' },
  { title: 'Email', dataIndex: 'email' },
  { title: 'Web Address', dataIndex: 'web_address' },
  { title: 'ID', dataIndex: 'id' },
];

export const PAGE_SIZE = 20;
