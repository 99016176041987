import { useCallback } from 'react';

import { Button, Input, message, Modal } from 'antd';

import { processorConfigDelete } from '@src/clients/3thix';
import { DeleteProcessorConfigRequest, Error3thix } from '@src/types';

type Props = {
  provider: string;
  id: string;
  toggleOpen: () => void;
  isOpen: boolean;
  onSuccess: () => void;
};

const ModalDeleteProcessorConfig = ({ provider, id, toggleOpen, isOpen, onSuccess }: Props) => {
  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const req: DeleteProcessorConfigRequest = {
        id: id,
        provider: provider,
      };

      const { status, data } = await processorConfigDelete(req);
      if (status !== 204) {
        message.error((data as Error3thix).message);
        return;
      }
      toggleOpen();
      onSuccess();
    },
    [id, provider, onSuccess, toggleOpen]
  );

  return (
    <Modal open={isOpen} footer={null} title="Processor Config" onOk={toggleOpen} onCancel={toggleOpen}>
      <div className="w-full mt-5">
        <form onSubmit={handleSubmit}>
          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="processor_entity_email">
              Provider
            </label>
            <Input
              required
              disabled
              size="large"
              type="text"
              name="processor_entity_email"
              defaultValue={provider}
              className="my-2 w-full"
            />
          </div>

          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="merchant_account">
              Processor Config ID
            </label>
            <Input
              required
              disabled
              size="large"
              type="text"
              name="merchant_account"
              defaultValue={id}
              className="my-2 w-full"
            />
          </div>

          <Button size="large" className="my-2" htmlType="submit" icon={<i className="fas fa-trash-alt" />}>
            Delete
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default ModalDeleteProcessorConfig;
