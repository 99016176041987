import { useCallback, useEffect, useState } from 'react';

import { Button, Input, message, Modal, Select } from 'antd';

import { accountList, addressList, processorConfigCreate, publisherRead } from '@src/clients/3thix';
import {
  Account,
  AccountInfoBankUS,
  Address,
  Error3thix,
  ProcessorConfigConfig,
  ProcessorConfigConfigAdyen,
  ProcessorConfigConfigNuvei,
  Publisher,
  SuccessAccountList,
  SuccessAddressList,
} from '@src/types';

type Props = {
  parentEntityID: string | null;
  entityID: string;
  toggleOpen: () => void;
  isOpen: boolean;
  onRefresh: () => void;
};

type ProcessorConfigFormData = {
  parent_id: string | null;
  processor_entity_id: string;
  processor_type: string;
  processor_entity_email: string;
  config: ProcessorConfigConfig;
};

const ModalCreateProcessorConfig = ({ entityID, parentEntityID, toggleOpen, isOpen, onRefresh }: Props) => {
  const [formData, setFormData] = useState<ProcessorConfigFormData>({
    parent_id: parentEntityID,
    processor_entity_id: entityID,
    processor_type: '',
    processor_entity_email: '',
    config: {} as ProcessorConfigConfig,
  });
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<string | null>(null);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<string | null>(null);

  let publisherID = entityID;
  if (parentEntityID) {
    publisherID = parentEntityID;
  }

  const fetchPublisher = useCallback(async () => {
    const { data, status } = await publisherRead(publisherID);
    if (status === 200) {
      const publisher = data as Publisher;
      setFormData(
        (old) =>
          ({
            ...old,
            config: {
              ...old.config,
              data: {
                legal_name: publisher.business_name || '',
                phone_number: publisher.phone || '',
                web_address: publisher.web_address || '',
              },
            },
          }) as ProcessorConfigFormData
      );
    }
  }, [publisherID]);

  const handleChangeSelect = useCallback(
    (value: string, fieldName: string) => {
      if (fieldName === 'processor_type') {
        if (value === 'NUVEI') {
          setFormData((old) => ({
            ...old,
            processor_type: value,
            config: {
              type: value,
              data: {
                merchant_id: '',
                merchant_site_id: '',
                merchant_secret_key: '',
              },
            },
          }));
        } else if (value === 'ADYEN') {
          setFormData((old) => ({
            ...old,
            processor_type: value,
            config: {
              type: value,
              data: {
                legal_name: '',
                reference: '',
                phone_number: '',
                web_address: '',
                routing_number: '',
                account_number: '',
                address1: '',
                address2: '',
                city: '',
                state_province: '',
                country_code: '',
                postal_code: '',
              },
            },
          }));
          fetchPublisher();
        }
      }
    },
    [fetchPublisher]
  );

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((old) => ({ ...old, [name]: value }));
  }, []);

  const handleConfigChange = useCallback((field: string, value: string) => {
    setFormData((old) => ({
      ...old,
      config: {
        ...old.config,
        data: {
          ...old.config.data,
          [field]: value,
        },
      },
    }));
  }, []);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const { status, data } = await processorConfigCreate(formData);
      if (status === 201) {
        onRefresh();
        toggleOpen();
        return;
      }
      message.error((data as Error3thix).message);
    },
    [formData, onRefresh, toggleOpen]
  );

  useEffect(() => {
    if (isOpen && formData.processor_type === 'ADYEN') {
      const fetchAddresses = async () => {
        const { data, status } = await addressList(publisherID, 1, 100);
        if (status === 200) {
          setAddresses((data as SuccessAddressList).addresses);
        } else {
          message.error('Failed to fetch addresses.');
        }
      };
      fetchAddresses();
      const fetchAccounts = async () => {
        const { data, status } = await accountList(1, 100, entityID);
        if (status === 200) {
          setAccounts((data as SuccessAccountList).accounts);
        } else {
          message.error('Failed to fetch accounts.');
        }
      };
      fetchAccounts();
    }
  }, [isOpen, formData.processor_type, entityID, publisherID]);

  const handleAddressSelect = useCallback(
    (value: string) => {
      const selected = addresses.find((addr) => addr.id === value);
      if (selected) {
        setSelectedAddress(value);
        setFormData((old) => ({
          ...old,
          config: {
            ...old.config,
            data: {
              ...old.config.data,
              address1: selected.address1 || '',
              address2: selected.address2 || '',
              city: selected.city || '',
              state_province: selected.state_province || '',
              country_code: selected.country_code || '',
              postal_code: selected.postal_code || '',
            },
          },
        }));
      }
    },
    [addresses]
  );

  const handleAccountSelect = useCallback(
    (value: string) => {
      const selected = accounts.find((acc) => acc.id === value);
      if (selected) {
        setSelectedAccount(value);
        setFormData((old) => ({
          ...old,
          config: {
            ...old.config,
            data: {
              ...old.config.data,
              routing_number: (selected.info.data as AccountInfoBankUS).routing_number || '',
              account_number: (selected.info.data as AccountInfoBankUS).account_number || '',
            },
          },
        }));
      }
    },
    [accounts]
  );

  return (
    <Modal
      open={isOpen}
      footer={null}
      title={
        <>
          <i className="fa-solid fa-cog"></i> New Processor Config
        </>
      }
      onOk={toggleOpen}
      onCancel={toggleOpen}
    >
      <div className="w-full mt-5">
        <form onSubmit={handleSubmit}>
          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="processor_type">
              Provider
            </label>
            <Select<string>
              size="large"
              value={formData.processor_type}
              className="my-2 w-full"
              onChange={(value) => handleChangeSelect(value, 'processor_type')}
            >
              <Select.Option value="NUVEI">Nuvei</Select.Option>
              <Select.Option value="ADYEN">Adyen</Select.Option>
            </Select>
          </div>

          <div className="w-full">
            <label htmlFor="processor_entity_email">Processor Email</label>
            <Input
              required
              size="large"
              type="email"
              name="processor_entity_email"
              value={formData.processor_entity_email}
              placeholder="Enter Processor Email"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>

          {formData.processor_type === 'NUVEI' && (
            <>
              <div className="w-full">
                <label htmlFor="merchant_id">Merchant ID</label>
                <Input
                  required
                  size="large"
                  type="text"
                  name="merchant_id"
                  value={(formData.config.data as ProcessorConfigConfigNuvei).merchant_id || ''}
                  placeholder="Enter Merchant ID"
                  className="my-2 w-full"
                  onChange={(e) => handleConfigChange('merchant_id', e.target.value)}
                />
              </div>
              <div className="w-full">
                <label htmlFor="merchant_site_id">Merchant Site ID</label>
                <Input
                  required
                  size="large"
                  type="text"
                  name="merchant_site_id"
                  value={(formData.config.data as ProcessorConfigConfigNuvei).merchant_site_id || ''}
                  placeholder="Enter Merchant Site ID"
                  className="my-2 w-full"
                  onChange={(e) => handleConfigChange('merchant_site_id', e.target.value)}
                />
              </div>
              <div className="w-full">
                <label htmlFor="merchant_secret_key">Merchant Secret Key</label>
                <Input
                  required
                  size="large"
                  type="text"
                  name="merchant_secret_key"
                  value={(formData.config.data as ProcessorConfigConfigNuvei).merchant_secret_key || ''}
                  placeholder="Enter Merchant API Key"
                  className="my-2 w-full"
                  onChange={(e) => handleConfigChange('merchant_secret_key', e.target.value)}
                />
              </div>
            </>
          )}

          {formData.processor_type === 'ADYEN' && (
            <>
              <div className="w-full">
                <label className="text-white font-[10px] ml-[10px]" htmlFor="address">
                  Select Address
                </label>
                <Select<string>
                  size="large"
                  className="my-2 w-full"
                  placeholder="Choose an address"
                  value={selectedAddress || undefined}
                  onChange={handleAddressSelect}
                >
                  {addresses.map((addr) => (
                    <Select.Option key={addr.id} value={addr.id}>
                      {[
                        addr.name,
                        addr.address1,
                        addr.address2,
                        addr.city,
                        addr.state_province,
                        addr.country_code,
                        addr.postal_code,
                      ]
                        .filter(Boolean)
                        .join(', ')}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div className="w-full">
                <label className="text-white font-[10px] ml-[10px]" htmlFor="account">
                  Select Account
                </label>
                <Select<string>
                  size="large"
                  className="my-2 w-full"
                  placeholder="Choose an account"
                  value={selectedAccount || undefined}
                  onChange={handleAccountSelect}
                >
                  {accounts
                    .filter((account) => account.info?.data as AccountInfoBankUS)
                    .map((account) => (
                      <Select.Option key={account.id} value={account.id}>
                        {[
                          account.name,
                          (account.info.data as AccountInfoBankUS).routing_number,
                          (account.info.data as AccountInfoBankUS).account_number,
                        ]
                          .filter(Boolean)
                          .join(', ')}
                      </Select.Option>
                    ))}
                </Select>
              </div>
              <div className="w-full">
                <label htmlFor="legal_name">Legal Name</label>
                <Input
                  required
                  size="large"
                  type="text"
                  name="legal_name"
                  value={(formData.config.data as ProcessorConfigConfigAdyen).legal_name || ''}
                  placeholder="Enter Merchant Site ID"
                  className="my-2 w-full"
                  onChange={(e) => handleConfigChange('legal_name', e.target.value)}
                />
              </div>
              <div className="flex flex-wrap justify-between">
                <InputField
                  label="Phone Number"
                  name="phone_number"
                  value={(formData.config.data as ProcessorConfigConfigAdyen).phone_number || ''}
                  onChange={(name, value) => handleConfigChange('phone_number', value)}
                />
                <InputField
                  label="Web Address"
                  name="web_address"
                  value={(formData.config.data as ProcessorConfigConfigAdyen).web_address || ''}
                  onChange={(name, value) => handleConfigChange('web_address', value)}
                />
              </div>
            </>
          )}
          <Button size="large" className="w-full" htmlType="submit" icon={<i className="fas fa-plus" />}>
            Create Processor Config
          </Button>
        </form>
      </div>
    </Modal>
  );
};

type InputFieldProps = {
  label: string;
  name: string;
  value: string;
  onChange: (name: string, value: string) => void;
  readOnly?: boolean;
};

const InputField = ({ label, name, value, onChange, readOnly = false }: InputFieldProps) => (
  <div className="flex flex-col w-[48%]">
    <label className="text-white font-[10px] ml-[10px]">{label}</label>
    <Input
      size="large"
      name={name}
      className="my-2 w-full"
      value={value}
      readOnly={readOnly}
      onChange={(e) => onChange(name, e.target.value)}
    />
  </div>
);

export default ModalCreateProcessorConfig;
