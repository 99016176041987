import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Skeleton, Tabs } from 'antd';
import Title from 'antd/es/typography/Title';

import { publisherRead } from '@src/clients/3thix';
import AccountsTable from '@src/components/AccountsTable';
import AddressesTable from '@src/components/AddressesTable';
import Container from '@src/components/Container';
import ContractsTable from '@src/components/ContractsTable';
import GamesTable from '@src/components/GamesTable';
import ProcessorConfigTable from '@src/components/ProcessorConfigTable';
import RolesTable from '@src/components/RolesTable';
import UserRolesTable from '@src/components/UserRolesTable';
import { Publisher as PublisherType } from '@src/types';

const Publisher = () => {
  const { id } = useParams();
  const [publisherInfos, setPublisherInfos] = useState<PublisherType | null>(null);

  const getPublisher = useCallback(async () => {
    if (!id) {
      return;
    }
    const { data, status } = await publisherRead(id);

    if (status === 200) {
      setPublisherInfos(data as PublisherType);
      return;
    }
  }, [id]);

  useEffect(() => {
    getPublisher();
  }, [getPublisher]);

  return (
    <Container
      breadcrumbs={[
        { name: 'Publishers', path: '/publishers' },
        { name: 'Publisher', path: `/publishers/${id}` },
      ]}
    >
      <div className="mb-[10px]">
        {publisherInfos ? (
          <Title level={2}>
            <i className="fa-solid fa-building"></i> {publisherInfos && publisherInfos.business_name}
          </Title>
        ) : (
          <Skeleton.Input />
        )}
      </div>

      <Tabs
        centered
        defaultActiveKey="1"
        items={[
          {
            label: 'Games',
            key: '1',
            children: <GamesTable publisherID={id as string} />,
          },
          {
            label: 'Roles',
            key: '2',
            children: <RolesTable entityID={id as string} />,
          },
          {
            label: 'User Roles',
            key: '3',
            children: <UserRolesTable parentEntityID={id as string} entityID={null} />,
          },
          {
            label: 'Addresses',
            key: '4',
            children: <AddressesTable entityID={id as string} />,
          },
          {
            label: 'Accounts',
            key: '5',
            children: <AccountsTable entityID={id as string} />,
          },
          {
            label: 'Processor config',
            key: '6',
            children: <ProcessorConfigTable parentEntityID={null} entityID={id as string} />,
          },
          {
            label: 'Contracts',
            key: '7',
            children: <ContractsTable parentEntityID={null} entityID={id as string} />,
          },
        ]}
      />
    </Container>
  );
};

export default Publisher;
