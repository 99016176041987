import { useCallback, useEffect, useMemo, useState } from 'react';

import { message, Skeleton, Table } from 'antd';

import { addressList } from '@src/clients/3thix';
import { Error3thix, SuccessAddressList } from '@src/types';

// TODO: Implement the following components in case we need them
// import ModalCreateAddress from './components/ModalCreateAddress';
// import ModalUpdateAddress from './components/ModalUpdateAddress';
import { COLUMNS, PAGE_SIZE } from './constants';

type Props = {
  entityID: string;
};

const AddressesTable = ({ entityID }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [addressesResp, setAddressesResp] = useState<SuccessAddressList | null>(null);
  // TODO: Implement the following states in case we need them
  // const [openCreateModal, setOpenCreateModal] = useState(false);
  // const [openUpdateModal, setOpenUpdateModal] = useState(false);
  // const [selectedAddress, setSelectedAddress] = useState<Address | null>(null);

  const getAddresses = useCallback(async () => {
    const { data, status } = await addressList(entityID, page, pageSize);

    if (status === 200) {
      setAddressesResp(data as SuccessAddressList);
      setIsLoading(false);
      return;
    }

    message.error((data as Error3thix).message);
  }, [entityID, page, pageSize]);

  useEffect(() => {
    getAddresses();
  }, [getAddresses]);

  // TODO: Implement the following function in case we need it
  // const refreshAddresses = useCallback(() => {
  //   getAddresses();
  // }, [getAddresses]);

  const rows = useMemo(() => {
    if (addressesResp === null) {
      return [];
    }

    return addressesResp.addresses;
  }, [addressesResp]);

  // TODO: Implement the following function in case we need it
  // const toggleCreateModal = useCallback(() => {
  //   setOpenCreateModal((prev) => !prev);
  // }, []);

  // const toggleUpdateModal = useCallback(() => {
  //   setOpenUpdateModal((prev) => !prev);
  // }, []);

  // const columns = useMemo(() => {
  //   return [
  //     {
  //       title: 'Actions',
  //       key: 'actions',
  //       render: (_: unknown, record: Address) => (
  //         <div style={{ display: 'flex', gap: '0.5rem' }}>
  //           <Tooltip title="Edit">
  //             <Button
  //               type="default"
  //               size="middle"
  //               icon={<i className="fas fa-edit" />}
  //               onClick={() => {
  //                 setSelectedAddress(record);
  //                 toggleUpdateModal();
  //               }}
  //             />
  //           </Tooltip>
  //         </div>
  //       ),
  //     },
  //     ...COLUMNS,
  //   ];
  // }, [toggleUpdateModal]);
  const columns = COLUMNS;

  return (
    <div className="text-right">
      {/* {entityID && (
        <Button
          type="default"
          size="large"
          icon={<i className="fas fa-plus" />}
          style={{ marginBottom: '20px' }}
          onClick={toggleCreateModal}
        >
          Create Address
        </Button>
      )} */}

      {/* <ModalCreateAddress
        entityID={entityID}
        isOpen={openCreateModal}
        toggleOpen={toggleCreateModal}
        onRefresh={refreshAddresses}
      />

      {selectedAddress && (
        <ModalUpdateAddress
          address={selectedAddress}
          isOpen={openUpdateModal}
          toggleOpen={toggleUpdateModal}
          onRefresh={refreshAddresses}
        />
      )} */}
      <div>{isLoading && <Skeleton />}</div>
      <div>
        {!isLoading && addressesResp !== null && (
          <Table
            dataSource={rows}
            columns={columns}
            scroll={{ x: '50%' }}
            pagination={{
              position: ['bottomCenter'],
              defaultCurrent: page,
              total: addressesResp.total,
              defaultPageSize: pageSize,
              onShowSizeChange: (current, size) => {
                setPageSize(size);
              },
              onChange: setPage,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default AddressesTable;
