export const RAIL_TYPES_MAP = {
  BANK: ['ACH'],
  CRYPTO: ['ETHEREUM', 'POLYGON', 'AVAX-C', 'TELOS', 'ARBITRUM', 'BNB_CHAIN', '3THIX-EBX', 'SKALE', 'ETX'],
  CARD: ['CREDIT_CARD'],
  APP: ['KAKAOPAY', 'PAYTM', 'KONBINI', 'AUPAY', 'PAYNOW'],
};

export const CRYPTO_CURRENCY_LIST = {
  ETH: 'ETHEREUM (ETH)',
  MATIC: 'POLYGON (MATIC)',
  AVAX: 'AVALANCHE (AVAX)',
  TLOS: 'TELOS (TLOS)',
  ARB: 'ARBITRUM (ARB)',
  BNB: 'BNB_CHAIN (BNB)',
  EBX: '3THIX (EBX)',
  sFUEL: 'sFuel (SKALE)',
  ETX: '3THIX (ETX)',
};

export const PROVIDER_RAIL_MAP = {
  ETHEREUM: ['ETHEREUM'],
  POLYGON: ['POLYGON'],
  'AVAX-C': ['AVAX-C', '3THIX-EBX'],
  TELOS: ['TELOS'],
  ARBITRUM: ['ARBITRUM'],
  BNB_CHAIN: ['BNB_CHAIN'],
  NEBULA: ['SKALE'],
  CREDIT_CARD: ['CREDIT_CARD'],
  ACH: ['ACH'],
  KAKAOPAY: ['KAKAOPAY'],
  PAYTM: ['PAYTM'],
  PAYNOW: ['PAYNOW'],
  KONBINI: ['KONBINI'],
  AUPAY: ['AUPAY'],
  '3THIX-EBX': ['3THIX-EBX'],
  '3THIX': ['ETX'],
};

export const RAIL_CURRENCY_MAP = {
  ETHEREUM: ['ETH'],
  POLYGON: ['MATIC'],
  'AVAX-C': ['AVAX', 'EBX'],
  TELOS: ['TLOS'],
  ARBITRUM: ['ARB'],
  BNB_CHAIN: ['BNB'],
  SKALE: ['sFUEL', 'EBX'],
  ACH: ['USD'],
  KAKAOPAY: ['KRW'],
  PAYTM: ['INR'],
  PAYNOW: ['SGD'],
  KONBINI: ['JPY'],
  AUPAY: ['JPY'],
  '3THIX-EBX': ['EBX'],
  CREDIT_CARD: ['USD', 'BRL', 'CAD', 'CNY', 'EUR', 'GBP', 'INR', 'JPY', 'KRW', 'SGD', 'TRY'],
  ETX: ['ETX'],
};

export const CURRENCY_DESCRIPTIONS: { [key: string]: string } = {
  ETH: 'Ethereum (ETH)',
  MATIC: 'Polygon (MATIC)',
  AVAX: 'Avalanche (AVAX)',
  TLOS: 'Telos (TLOS)',
  ARB: 'Arbitrum (ARB)',
  BNB: 'BNB Chain (BNB)',
  USD: 'US Dollar (USD)',
  BRL: 'Brazilian Real (BRL)',
  CAD: 'Canadian Dollar (CAD)',
  CNY: 'Chinese Yuan (CNY)',
  EUR: 'Euro (EUR)',
  GBP: 'British Pound (GBP)',
  INR: 'Indian Rupee (INR)',
  JPY: 'Japanese Yen (JPY)',
  KRW: 'Korean Won (KRW)',
  SGD: 'Singapore Dollar (SGD)',
  TRY: 'Turkish Lira (TRY)',
  EBX: '3THIX (EBX)',
  sFUEL: 'sFuel (SKALE)',
  ETX: '3THIX (ETX)',
};

export const roleTypes = [
  'PAYMENT',
  'REVENUE',
  'FULFILLMENT',
  'PROXY',
  'SETTLEMENT',
  'FEE_IN',
  'FEE_OUT',
  'MINT',
  'BURN',
  'RELAYER',
];

export const permissionTypes = [
  'ROLE_MANAGE',
  'PUBLISHER_MANAGE',
  'GAME_MANAGE',
  'ACCOUNT_MANAGE',
  'FEE_POLICY_MANAGE',
  'API_KEY_MANAGE',
];

export const NotificationEventsWebhookCustomer = ['ORDER_SETTLED', 'ORDER_FAILED'];

export const NotificationEventsWebhook3thix = [
  'LOG_ERROR',
  'NEW_CHARGEBACK_TO_FILTER',
  ...NotificationEventsWebhookCustomer,
];

export const NotificationEventsEmail3thix = ['AUTHORIZE_LOGIN_TOKEN', 'UPDATE_CHARGEBACK_TO_MERCHANT'];
