import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Input, message, Modal } from 'antd';

import { publisherCreate } from '@src/clients/3thix';
import { CreatePublisherRequest, Error3thix, SuccessPublisherCreate } from '@src/types';

type Props = {
  toggleOpen: () => void;
  isOpen: boolean;
};

const ModalCreatePublisher = ({ toggleOpen, isOpen }: Props) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<CreatePublisherRequest>({
    name: '',
    address1: '',
    address2: '',
    city: '',
    state_province: '',
    postal_code: '',
    country_code: '',
    contact_name: '',
    contact_phone: '',
    contact_email: '',
    web_address: '',
  });

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  }, []);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const { status, data } = await publisherCreate(formData);
      if (status === 201) {
        navigate(`/publishers/${(data as SuccessPublisherCreate).publisher_id}`);
        return;
      }

      message.error((data as Error3thix).message);
    },
    [formData, navigate]
  );

  return (
    <Modal
      open={isOpen}
      footer={null}
      title={
        <>
          <i className="fa-solid fa-building"></i> New Publisher
        </>
      }
      onOk={toggleOpen}
      onCancel={toggleOpen}
    >
      <div className="w-full mt-5">
        <form onSubmit={handleSubmit}>
          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="country">
              Name
            </label>
            <Input
              required
              size="large"
              type="text"
              name="name"
              defaultValue={formData.name}
              placeholder="Game Studio SA"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>
          <div className="grid gap-6 md:grid-cols-2">
            <div className="w-full">
              <label className="text-white font-[10px] ml-[10px]" htmlFor="country">
                Address 1
              </label>
              <Input
                required
                size="large"
                type="text"
                name="address1"
                defaultValue={formData.address1}
                placeholder="123 Main St., Suite 400"
                className="my-2 w-full"
                onChange={handleChange}
              />
            </div>
            <div className="w-full">
              <label className="text-white font-[10px] ml-[10px]" htmlFor="country">
                Address 2
              </label>
              <Input
                required
                size="large"
                type="text"
                name="address2"
                defaultValue={formData.address2}
                placeholder="Building J"
                className="my-2 w-full"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="grid gap-6 md:grid-cols-2">
            <div className="w-full">
              <label className="text-white font-[10px] ml-[10px]" htmlFor="country">
                City
              </label>
              <Input
                required
                size="large"
                type="text"
                name="city"
                defaultValue={formData.city}
                placeholder="Los Angeles"
                className="my-2 w-full"
                onChange={handleChange}
              />
            </div>
            <div className="w-full">
              <label className="text-white font-[10px] ml-[10px]" htmlFor="country">
                State Province
              </label>
              <Input
                required
                size="large"
                type="text"
                name="state_province"
                defaultValue={formData.state_province}
                placeholder="CA"
                className="my-2 w-full"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="grid gap-6 md:grid-cols-2">
            <div className="w-full">
              <label className="text-white font-[10px] ml-[10px]" htmlFor="country">
                Postal Code
              </label>
              <Input
                required
                size="large"
                type="text"
                name="postal_code"
                defaultValue={formData.postal_code}
                placeholder="90001"
                className="my-2 w-full"
                onChange={handleChange}
              />
            </div>
            <div className="w-full">
              <label className="text-white font-[10px] ml-[10px]" htmlFor="country">
                Country Code
              </label>
              <Input
                required
                size="large"
                type="text"
                name="country_code"
                defaultValue={formData.country_code}
                placeholder="US"
                className="my-2 w-full"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="country">
              Contact Name
            </label>
            <Input
              required
              size="large"
              type="text"
              name="contact_name"
              defaultValue={formData.contact_name}
              placeholder="John Doe"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>
          <div className="grid gap-6 md:grid-cols-2">
            <div className="w-full">
              <label className="text-white font-[10px] ml-[10px]" htmlFor="country">
                Phone
              </label>
              <Input
                required
                size="large"
                type="phone"
                name="contact_phone"
                defaultValue={formData.contact_phone}
                placeholder="+11234567890"
                className="my-2 w-full"
                onChange={handleChange}
              />
            </div>
            <div className="w-full">
              <label className="text-white font-[10px] ml-[10px]" htmlFor="country">
                E-mail
              </label>
              <Input
                required
                size="large"
                type="email"
                name="contact_email"
                defaultValue={formData.contact_email}
                placeholder="johndoe@gamingstudios.com"
                className="my-2 w-full"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="web_address">
              Web Address
            </label>
            <Input
              required
              size="large"
              type="text"
              name="web_address"
              defaultValue={formData.contact_email}
              placeholder="https://www.gamingstudios.com"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>
          <Button size="large" className="my-2" htmlType="submit" icon={<i className="fas fa-plus" />}>
            Create
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default ModalCreatePublisher;
