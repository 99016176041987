import { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, Flex, message, Skeleton, Table, Tooltip } from 'antd';

import { processorConfigList } from '@src/clients/3thix';
import { Error3thix, ProcessorConfig, SuccessProcessorConfigList } from '@src/types';

import ModalCreateProcessorConfig from './components/ModalCreateProcessorConfig';
import ModalDeleteProcessorConfig from './components/ModalDeleteProcessorConfig';
import ModalViewProcessorConfig from './components/ModalViewProcessorConfig';
import { COLUMNS, PAGE_SIZE } from './constants';

type Props = {
  parentEntityID: string | null;
  entityID: string;
};

const ProcessorConfigsTable = ({ entityID, parentEntityID }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [processorConfigsResp, setProcessorConfigsResp] = useState<SuccessProcessorConfigList | null>(null);
  const [selectedProcessorConfig, setSelectedProcessorConfig] = useState<ProcessorConfig | null>(null);

  console.log('parentEntityID', parentEntityID);
  const getProcessorConfigs = useCallback(async () => {
    setIsLoading(true);
    const { data, status } = await processorConfigList(entityID, page, pageSize);

    if (status === 200) {
      setProcessorConfigsResp(data as SuccessProcessorConfigList);
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
    message.error((data as Error3thix).message);
  }, [page, pageSize, entityID]);

  const toggleCreateModal = useCallback(() => {
    setOpenCreateModal((prev) => !prev);
  }, []);

  const toggleDeleteModal = useCallback(() => {
    setOpenDeleteModal((prev) => !prev);
  }, []);

  const toggleViewModal = useCallback(() => {
    setOpenViewModal((prev) => !prev);
  }, []);

  useEffect(() => {
    getProcessorConfigs();
  }, [getProcessorConfigs]);

  const refreshProcessorConfigs = useCallback(() => {
    getProcessorConfigs();
  }, [getProcessorConfigs]);

  const rows = useMemo(() => {
    if (processorConfigsResp === null) {
      return [];
    }

    return processorConfigsResp.processor_configs;
  }, [processorConfigsResp]);

  const columns = useMemo(() => {
    return [
      {
        title: 'Actions',
        key: 'actions',
        render: (_: unknown, record: ProcessorConfig) => (
          <Flex align="flex-start" gap="small">
            <Tooltip title="View">
              <Button
                type="default"
                size="middle"
                icon={<i className="fas fa-eye" />}
                onClick={() => {
                  setSelectedProcessorConfig(record);
                  toggleViewModal();
                }}
              />
            </Tooltip>
            <Tooltip title="Delte">
              <Button
                type="default"
                size="middle"
                icon={<i className="fas fa-trash-alt" />}
                onClick={() => {
                  setSelectedProcessorConfig(record);
                  toggleDeleteModal();
                }}
              />
            </Tooltip>
          </Flex>
        ),
      },
      ...COLUMNS,
    ];
  }, [setSelectedProcessorConfig, toggleDeleteModal, toggleViewModal]);

  return (
    <div className="text-right">
      {entityID && (
        <Button
          type="default"
          size="large"
          icon={<i className="fas fa-plus" />}
          style={{ marginBottom: '20px' }}
          onClick={toggleCreateModal}
        >
          Create ProcessorConfig
        </Button>
      )}

      <ModalCreateProcessorConfig
        parentEntityID={parentEntityID}
        entityID={entityID}
        isOpen={openCreateModal}
        toggleOpen={toggleCreateModal}
        onRefresh={refreshProcessorConfigs}
      />

      {selectedProcessorConfig && (
        <ModalDeleteProcessorConfig
          provider={selectedProcessorConfig.processor_type}
          id={selectedProcessorConfig.id}
          isOpen={openDeleteModal}
          toggleOpen={toggleDeleteModal}
          onSuccess={getProcessorConfigs}
        />
      )}

      {selectedProcessorConfig && (
        <ModalViewProcessorConfig
          processorConfig={selectedProcessorConfig}
          isOpen={openViewModal}
          toggleOpen={toggleViewModal}
          onRefresh={getProcessorConfigs}
        />
      )}

      <div>{isLoading && <Skeleton />}</div>
      <div>
        {!isLoading && processorConfigsResp !== null && (
          <Table
            dataSource={rows}
            columns={columns}
            scroll={{ x: '50%' }}
            pagination={{
              position: ['bottomCenter'],
              defaultCurrent: page,
              total: processorConfigsResp.total,
              defaultPageSize: pageSize,
              onShowSizeChange: (current, size) => {
                setPageSize(size);
              },
              onChange: setPage,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ProcessorConfigsTable;
