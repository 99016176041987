import axios, { AxiosError } from 'axios';

import { clearToken, getToken } from '@src/hooks/useLogin/utils';
import {
  AdyenProcessorConfig,
  ApiKey,
  Balance,
  Campaign,
  Contract,
  CreateAccountRequest,
  CreateAddressRequest,
  CreateAdyenProcessorConfigReq,
  CreateCampaignGameReq,
  CreateCampaignReq,
  CreateContractRequest,
  CreateEntityRoleRequest,
  CreateFeePolicyRequest,
  CreateGameRequest,
  CreateNotificationEmailConfig,
  CreateNotificationEmailConfigSuccess,
  CreateNotificationWebhook,
  CreateNotificationWebhookSuccess,
  CreateNuveiProcessorConfigReq,
  CreateProcessorConfigRequest,
  CreatePublisherRequest,
  CreateTransactionsLimitsReq,
  CreateTransferRequest,
  CreateVendorRequest,
  DeleteProcessorConfigRequest,
  Error3thix,
  Game,
  GrantRoleRequest,
  ListAuditRequest,
  ListChargebackResp,
  ListLedgerRequest,
  ListNotificationEmailConfigsReq,
  ListNotificationEmailConfigsResp,
  ListNotificationHistoriesReq,
  ListNotificationHistoriesResp,
  ListNotificationWebhooksReq,
  ListNotificationWebhooksResp,
  NuveiProcessorConfig,
  Publisher,
  ReadProcessorConfigRequest,
  ReadProcessorConfigResponse,
  RevokeRoleRequest,
  SearchChargebackReq,
  SearchOrderReq,
  SuccessAccountList,
  SuccessAddressList,
  SuccessApiKeyCreate,
  SuccessAuditList,
  SuccessCampaignGameList,
  SuccessCampaignList,
  SuccessContractCreate,
  SuccessContractList,
  SuccessEntityRoleList,
  SuccessFeePolicyCreate,
  SuccessFeePolicyList,
  SuccessFulfillmentsList,
  SuccessGameCreate,
  SuccessGamesList,
  SuccessInvoicesList,
  SuccessLedgerList,
  SuccessLimitsGet,
  SuccessOrderCreate,
  SuccessOrdersList,
  SuccessProcessorConfigList,
  SuccessPublisherCreate,
  SuccessPublisherList,
  SuccessTransactionsLimitsCreate,
  SuccessTransactionsLimitsList,
  SuccessTransactionsLimitsSet,
  SuccessUserList,
  SuccessUserRead,
  SuccessUserRoleList,
  SuccessVendorCreate,
  SuccessVendorList,
  UpdateAccountRequest,
  UpdateAddressRequest,
  UpdateAdyenProcessorConfigReq,
  UpdateCampaignGameReq,
  UpdateChargeback,
  UpdateContractRequest,
  UpdateEntityRoleRequest,
  UpdateFeePolicyRequest,
  UpdateNuveiProcessorConfigReq,
  UpdatePublisherRequest,
  UpdateVendorRequest,
} from '@src/types';
import { getSelectedEntityId } from '@src/utils/EntityId';

type RespAPI<Success> = {
  status: number;
  data: Success | Error3thix;
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_3THIX_API,
  timeout: 3000,
  headers: {},
});

instance.interceptors.request.use((request) => {
  const token = getToken();

  if (token !== null) {
    request.headers.Authorization = `Bearer ${token}`;
  }

  // Set the entity ID header if it is available
  const entityId = getSelectedEntityId();
  if (entityId) {
    request.headers['X-Entity-Id'] = entityId;
  }

  return request;
});

instance.interceptors.response.use(
  (response) => response,
  (error: AxiosError): RespAPI<AxiosError> => {
    // loggout user when he's not authorized
    if (error.response?.status === 401) {
      clearToken();
    }

    return {
      status: error.response?.status || 500,
      data: (error.response?.data as Error3thix) || { message: error.message },
    };
  }
);

export async function userList(
  page: number,
  page_size: number,
  parent_entity_id: string | null,
  email: string | null,
  is_admin: boolean | null
): Promise<RespAPI<SuccessUserList>> {
  const response = await instance.post('/entity/user/list', {
    page,
    page_size,
    parent_entity_id,
    email,
    is_admin,
  });

  return {
    status: response.status,
    data: response.data,
  };
}

export async function userMe(): Promise<RespAPI<SuccessUserRead>> {
  const response = await instance.get('/entity/user/me');

  return {
    status: response.status,
    data: response.data,
  };
}

export async function userRead(user_id: string): Promise<RespAPI<SuccessUserRead>> {
  const response = await instance.post('/entity/user/read', { user_id });

  return {
    status: response.status,
    data: response.data,
  };
}

export async function userCreate(payload: { entity_id: string; email: string }): Promise<RespAPI<null>> {
  const response = await instance.post('/entity/user/create', payload);

  return {
    status: response.status,
    data: response.data,
  };
}

export async function rootRead(): Promise<RespAPI<string>> {
  const response = await instance.post('/entity/root/read');

  return {
    status: response.status,
    data: response.data,
  };
}

export async function selfPromote(secret_key: string): Promise<RespAPI<null>> {
  const response = await instance.post('/entity/user/admin/grant/me', { secret_key });

  return {
    status: response.status,
    data: response.data,
  };
}

export async function userGrantAdmin(user_id: string): Promise<RespAPI<null>> {
  const response = await instance.post('/entity/user/admin/grant', {
    user_id,
  });

  return {
    status: response.status,
    data: response.data,
  };
}

export async function userRevokeAdmin(user_id: string): Promise<RespAPI<null>> {
  const response = await instance.post('/entity/user/admin/revoke', {
    user_id,
  });

  return {
    status: response.status,
    data: response.data,
  };
}

export async function publisherRead(id: string): Promise<RespAPI<Publisher>> {
  const response = await instance.post('/entity/publisher/read', { id });
  return {
    status: response.status,
    data: response.data,
  };
}

export async function publisherList(page: number, page_size: number): Promise<RespAPI<SuccessPublisherList>> {
  const response = await instance.post('/entity/publisher/list', { page, page_size: page_size });
  return {
    status: response.status,
    data: response.data,
  };
}

export async function publisherCreate(payload: CreatePublisherRequest): Promise<RespAPI<SuccessPublisherCreate>> {
  const response = await instance.post('/entity/publisher/create', payload);
  return {
    status: response.status,
    data: response.data,
  };
}

export async function publisherUpdate(payload: UpdatePublisherRequest): Promise<RespAPI<null>> {
  const response = await instance.put('/entity/publisher/update', payload);
  return {
    status: response.status,
    data: response.data,
  };
}

export async function allOrdersList(
  page: number,
  page_size: number,
  search: SearchOrderReq
): Promise<RespAPI<SuccessOrdersList>> {
  const response = await instance.post('/order/list', { page, page_size, ...search });
  return {
    status: response.status,
    data: response.data,
  };
}

export async function auditList(
  page: number,
  page_size: number,
  search: ListAuditRequest
): Promise<RespAPI<SuccessAuditList>> {
  const response = await instance.post('/audit/list', { page, page_size, ...search });
  return {
    status: response.status,
    data: response.data,
  };
}

export async function ledgerList(
  page: number,
  page_size: number,
  search: ListLedgerRequest
): Promise<RespAPI<SuccessLedgerList>> {
  const response = await instance.post('/ledger/list', { page, page_size, ...search });
  return {
    status: response.status,
    data: response.data,
  };
}

export async function ordersListByGame(
  page: number,
  page_size: number,
  issuer_entity_id?: string,
  fulfillment_entity_id?: string
): Promise<RespAPI<SuccessOrdersList>> {
  const response = await instance.post('/order/list', { page, page_size, issuer_entity_id, fulfillment_entity_id });
  return {
    status: response.status,
    data: response.data,
  };
}

export async function invoiceList(
  page: number,
  page_size: number,
  order_id: string
): Promise<RespAPI<SuccessInvoicesList>> {
  const response = await instance.post('/invoice/list', { page, page_size, order_id });
  return {
    status: response.status,
    data: response.data,
  };
}

export async function fulfillmentList(
  page: number,
  page_size: number,
  order_id: string
): Promise<RespAPI<SuccessFulfillmentsList>> {
  const response = await instance.post('/fulfillment/list', { page, page_size, order_id });
  return {
    status: response.status,
    data: response.data,
  };
}

export async function gameRead(id: string): Promise<RespAPI<Game>> {
  const response = await instance.post('/entity/game/read', { id });
  return {
    status: response.status,
    data: response.data,
  };
}

export async function gamesListByPublisher(
  publisher_id: string,
  page: number,
  page_size: number
): Promise<RespAPI<SuccessGamesList>> {
  const response = await instance.post('/entity/game/publisher/list', {
    publisher_id,
    page,
    page_size,
  });

  return {
    status: response.status,
    data: response.data,
  };
}

export async function gameCreate(payload: CreateGameRequest): Promise<RespAPI<SuccessGameCreate>> {
  const response = await instance.post('/entity/game/create', payload);
  return {
    status: response.status,
    data: response.data,
  };
}

export async function apiKeysListByGame(game_id: string): Promise<RespAPI<ApiKey[]>> {
  const response = await instance.post('/entity/apikey/list', {
    game_id,
  });

  return {
    status: response.status,
    data: response.data,
  };
}

export async function apiKeysCreate(game_id: string): Promise<RespAPI<SuccessApiKeyCreate>> {
  const response = await instance.post('/entity/apikey/create', {
    game_id,
  });

  return {
    status: response.status,
    data: response.data,
  };
}

export async function apiKeysDeactivated(id: string): Promise<RespAPI<SuccessApiKeyCreate>> {
  const response = await instance.post('/entity/apikey/deactivate', {
    id,
  });

  return {
    status: response.status,
    data: response.data,
  };
}

export async function accountList(
  page: number,
  page_size: number,
  owner_entity_id: string
): Promise<RespAPI<SuccessAccountList>> {
  const response = await instance.post('/account/list', { page, page_size, owner_entity_id });

  return {
    status: response.status,
    data: response.data,
  };
}

export async function accountSync(account_currency_id: string): Promise<RespAPI<null>> {
  const response = await instance.post('/account/balance/sync', { account_currency_id });

  return {
    status: response.status,
    data: response.data,
  };
}

export async function balanceCurrent(account_currency_id: string): Promise<RespAPI<Balance>> {
  const response = await instance.post('/account/balance/current', { account_currency_id });

  return {
    status: response.status,
    data: response.data,
  };
}

export async function feePolicyList(
  entity_id: string | null,
  page: number,
  page_size: number
): Promise<RespAPI<SuccessFeePolicyList>> {
  const response = await instance.post('/fees/policies/list', { entity_id, page, page_size });

  return {
    status: response.status,
    data: response.data,
  };
}

export async function accountCreate(payload: CreateAccountRequest): Promise<RespAPI<null>> {
  const response = await instance.post('/account/create', payload);

  return {
    status: response.status,
    data: response.data,
  };
}

export async function accountUpdate(payload: UpdateAccountRequest): Promise<RespAPI<null>> {
  const response = await instance.put('/account/update', payload);

  return {
    status: response.status,
    data: response.data,
  };
}

export async function feePolicyCreate(payload: CreateFeePolicyRequest): Promise<RespAPI<SuccessFeePolicyCreate>> {
  const response = await instance.post('/fees/policies/create', payload);

  return {
    status: response.status,
    data: response.data,
  };
}

export async function feePolicyUpdate(payload: UpdateFeePolicyRequest): Promise<RespAPI<null>> {
  const response = await instance.put('/fees/policies/update', payload);

  return {
    status: response.status,
    data: response.data,
  };
}

export async function transferCreate(payload: CreateTransferRequest): Promise<RespAPI<SuccessOrderCreate>> {
  const response = await instance.post('/order/transfer/create', payload);

  return {
    status: response.status,
    data: response.data,
  };
}

export async function notificationEmailConfigsList(
  page: number,
  page_size: number,
  search: ListNotificationEmailConfigsReq
): Promise<RespAPI<ListNotificationEmailConfigsResp>> {
  const response = await instance.post('/notification/email/list', { page, page_size, ...search });

  return {
    status: response.status,
    data: response.data,
  };
}

export async function notificationEmailConfigsCreate(
  payload: CreateNotificationEmailConfig
): Promise<RespAPI<CreateNotificationEmailConfigSuccess>> {
  const response = await instance.post('/notification/email/create', payload);
  return {
    status: response.status,
    data: response.data,
  };
}

export async function notificationEmailConfigsUpdate(id: string, destination_email: string): Promise<RespAPI<null>> {
  const response = await instance.put('/notification/email/update', { id, destination_email });
  return {
    status: response.status,
    data: response.data,
  };
}

export async function notificationEmailConfigsEnableToggle(
  id: string,
  toggleEnable: 'enable' | 'disable'
): Promise<RespAPI<null>> {
  const response = await instance.put(`/notification/email/${toggleEnable}`, { id });
  return {
    status: response.status,
    data: response.data,
  };
}

export async function notificationWebhooksList(
  page: number,
  page_size: number,
  search: ListNotificationWebhooksReq
): Promise<RespAPI<ListNotificationWebhooksResp>> {
  const response = await instance.post('/notification/webhook/list', { page, page_size, ...search });

  return {
    status: response.status,
    data: response.data,
  };
}

export async function notificationHistoryList(
  page: number,
  page_size: number,
  search: ListNotificationHistoriesReq
): Promise<RespAPI<ListNotificationHistoriesResp>> {
  const response = await instance.post('/notification/history/list', { page, page_size, ...search });

  return {
    status: response.status,
    data: response.data,
  };
}

export async function notificationWebhooksCreate(
  payload: CreateNotificationWebhook
): Promise<RespAPI<CreateNotificationWebhookSuccess>> {
  const response = await instance.post('/notification/webhook/create', payload);
  return {
    status: response.status,
    data: response.data,
  };
}

export async function notificationWebhooksUpdate(id: string, destination_email: string): Promise<RespAPI<null>> {
  const response = await instance.put('/notification/webhook/update', { id, destination_email });
  return {
    status: response.status,
    data: response.data,
  };
}

export async function notificationWebhooksEnableToggle(
  id: string,
  toggleEnable: 'enable' | 'disable'
): Promise<RespAPI<null>> {
  const response = await instance.put(`/notification/webhook/${toggleEnable}`, { id });
  return {
    status: response.status,
    data: response.data,
  };
}

export async function roleList(
  entity_id: string | null,
  page: number,
  page_size: number
): Promise<RespAPI<SuccessEntityRoleList>> {
  const response = await instance.post('/entity/role/list', { entity_id, page, page_size });
  return {
    status: response.status,
    data: response.data,
  };
}

export async function transactionsLimitsList(
  page: number,
  page_size: number,
  name?: string
): Promise<RespAPI<SuccessTransactionsLimitsList>> {
  const response = await instance.post('/entity/transactions-limits/list', {
    page,
    page_size,
    name,
  });

  return {
    status: response.status,
    data: response.data,
  };
}

export async function entityRoleCreate(payload: CreateEntityRoleRequest): Promise<RespAPI<null>> {
  const response = await instance.post('/entity/role/create', payload);
  return {
    status: response.status,
    data: response.data,
  };
}

export async function transactionsLimitsGet(game_entity_id: string): Promise<RespAPI<SuccessLimitsGet>> {
  const response = await instance.post('/entity/game/transactions-limits/get', {
    game_entity_id,
  });

  return {
    status: response.status,
    data: response.data,
  };
}

export async function entityRoleUpdate(payload: UpdateEntityRoleRequest): Promise<RespAPI<null>> {
  const response = await instance.put('/entity/role/update', payload);
  return {
    status: response.status,
    data: response.data,
  };
}

export async function transactionsLimitsCreate(
  payload: CreateTransactionsLimitsReq
): Promise<RespAPI<SuccessTransactionsLimitsCreate>> {
  const response = await instance.post('/entity/transactions-limits/create', payload);
  return {
    status: response.status,
    data: response.data,
  };
}

export async function userRoleList(
  parent_entity_id: string | null,
  entity_id: string | null,
  page: number,
  page_size: number
): Promise<RespAPI<SuccessUserRoleList>> {
  const response = await instance.post('/entity/user/role/list', { parent_entity_id, entity_id, page, page_size });
  return {
    status: response.status,
    data: response.data,
  };
}

export async function transactionsLimitsSet(
  game_entity_id: string,
  transactions_limits_id: string
): Promise<RespAPI<SuccessTransactionsLimitsSet>> {
  const response = await instance.post('/entity/game/transactions-limits/set', {
    game_entity_id,
    transactions_limits_id,
  });
  return {
    status: response.status,
    data: response.data,
  };
}

export async function grantUserRole(payload: GrantRoleRequest): Promise<RespAPI<null>> {
  const response = await instance.post('/entity/user/role/grant', payload);
  return {
    status: response.status,
    data: response.data,
  };
}

export async function revokeUserRole(payload: RevokeRoleRequest): Promise<RespAPI<null>> {
  const response = await instance.post('/entity/user/role/revoke', payload);
  return {
    status: response.status,
    data: response.data,
  };
}

export async function transactionsLimitsUnset(
  game_entity_id: string,
  transactions_limits_id: string
): Promise<RespAPI<SuccessTransactionsLimitsSet>> {
  const response = await instance.post('/entity/game/transactions-limits/unset', {
    game_entity_id,
    transactions_limits_id,
  });
  return {
    status: response.status,
    data: response.data,
  };
}

export async function campaignList(
  name: string,
  page: number,
  page_size: number
): Promise<RespAPI<SuccessCampaignList>> {
  const response = await instance.post('/campaign/list', {
    name,
    page,
    page_size,
  });

  return {
    status: response.status,
    data: response.data,
  };
}

export async function campaignCreate(req: CreateCampaignReq): Promise<RespAPI<Campaign>> {
  const response = await instance.post('/campaign/create', req);

  return {
    status: response.status,
    data: response.data,
  };
}

export async function campaignGameList(
  game_entity_id: string,
  page: number,
  page_size: number
): Promise<RespAPI<SuccessCampaignGameList>> {
  const response = await instance.post('/campaign/game/list', {
    game_entity_id,
    page,
    page_size,
  });

  return {
    status: response.status,
    data: response.data,
  };
}

export async function campaignGameSet(req: CreateCampaignGameReq): Promise<RespAPI<string>> {
  const response = await instance.post('/campaign/game/set', req);

  return {
    status: response.status,
    data: response.data,
  };
}

export async function campaignGameEnableToggle(id: string, toggleEnable: 'enable' | 'disable'): Promise<RespAPI<null>> {
  const response = await instance.put(`/campaign/game/${toggleEnable}`, { id });
  return {
    status: response.status,
    data: response.data,
  };
}

export async function campaignEnableToggle(id: string, toggleEnable: 'enable' | 'disable'): Promise<RespAPI<null>> {
  const response = await instance.put(`/campaign/${toggleEnable}`, { id });
  return {
    status: response.status,
    data: response.data,
  };
}

export async function campaignGameUpdate(req: UpdateCampaignGameReq): Promise<RespAPI<null>> {
  const response = await instance.put('/campaign/game/update', req);
  return {
    status: response.status,
    data: response.data,
  };
}

export async function addressList(
  entity_id: string | null,
  page: number,
  page_size: number
): Promise<RespAPI<SuccessAddressList>> {
  const response = await instance.post('/entity/address/list', { entity_id, page, page_size });
  return {
    status: response.status,
    data: response.data,
  };
}

export async function addressCreate(req: CreateAddressRequest): Promise<RespAPI<null>> {
  const response = await instance.post('/entity/address/create', req);
  return {
    status: response.status,
    data: response.data,
  };
}

export async function addressUpdate(req: UpdateAddressRequest): Promise<RespAPI<null>> {
  const response = await instance.put('/entity/address/update', req);
  return {
    status: response.status,
    data: response.data,
  };
}

export async function processorConfigList(
  processor_entity_id: string | null,
  page: number,
  page_size: number
): Promise<RespAPI<SuccessProcessorConfigList>> {
  const response = await instance.post('/entity/processor/config/list', { processor_entity_id, page, page_size });
  return {
    status: response.status,
    data: response.data,
  };
}

export async function processorConfigCreate(req: CreateProcessorConfigRequest): Promise<RespAPI<{ id: string }>> {
  const response = await instance.post('/entity/processor/config/create', req);
  return {
    status: response.status,
    data: response.data,
  };
}

export async function processorConfigRead(
  req: ReadProcessorConfigRequest
): Promise<RespAPI<ReadProcessorConfigResponse>> {
  const response = await instance.post('/entity/processor/config/read', req);
  return {
    status: response.status,
    data: response.data,
  };
}

// Delete processor config
export async function processorConfigDelete(req: DeleteProcessorConfigRequest): Promise<RespAPI<null>> {
  const response = await instance.post('/entity/processor/config/delete', req);
  return {
    status: response.status,
    data: response.data,
  };
}

export async function nuveiProcessorConfigCreate(req: CreateNuveiProcessorConfigReq): Promise<RespAPI<{ id: string }>> {
  const response = await instance.post('/nuvei/processor/config/create', req);
  return {
    status: response.status,
    data: response.data,
  };
}

export async function adyenProcessorConfigCreate(req: CreateAdyenProcessorConfigReq): Promise<RespAPI<{ id: string }>> {
  const response = await instance.post('/adyen/processor/config/create', req);
  return {
    status: response.status,
    data: response.data,
  };
}

export async function nuveiProcessorConfigGet(processor_entity_id): Promise<RespAPI<NuveiProcessorConfig>> {
  const response = await instance.post('/nuvei/processor/config/get', { processor_entity_id });
  return {
    status: response.status,
    data: response.data,
  };
}

export async function adyenProcessorConfigGet(processor_entity_id): Promise<RespAPI<AdyenProcessorConfig>> {
  const response = await instance.post('/adyen/processor/config/get', { processor_entity_id });
  return {
    status: response.status,
    data: response.data,
  };
}

export async function nuveiProcessorConfigUpdate(req: UpdateNuveiProcessorConfigReq): Promise<RespAPI<null>> {
  const response = await instance.put('/nuvei/processor/config/update', req);
  return {
    status: response.status,
    data: response.data,
  };
}

export async function adyenProcessorConfigUpdate(req: UpdateAdyenProcessorConfigReq): Promise<RespAPI<null>> {
  const response = await instance.put('/adyen/processor/config/update', req);
  return {
    status: response.status,
    data: response.data,
  };
}

export async function nuveiProcessorConfigDelete(id: string): Promise<RespAPI<null>> {
  const response = await instance.delete('/nuvei/processor/config/delete', { data: { id } });
  return {
    status: response.status,
    data: response.data,
  };
}

export async function adyenProcessorConfigDelete(id: string): Promise<RespAPI<null>> {
  const response = await instance.delete('/adyen/processor/config/delete', { data: { id } });
  return {
    status: response.status,
    data: response.data,
  };
}

export async function contractList(
  entity_id: string | null,
  page: number,
  page_size: number
): Promise<RespAPI<SuccessContractList>> {
  const response = await instance.post('/entity/contracts/list', { entity_id, page, page_size });
  return {
    status: response.status,
    data: response.data,
  };
}

export async function contractCreate(req: CreateContractRequest): Promise<RespAPI<SuccessContractCreate>> {
  const response = await instance.post('/entity/contracts/create', req);
  return {
    status: response.status,
    data: response.data,
  };
}

export async function contractUpdate(req: UpdateContractRequest): Promise<RespAPI<null>> {
  const response = await instance.put('/entity/contracts/update', req);
  return {
    status: response.status,
    data: response.data,
  };
}

export async function contractRead(id: string): Promise<RespAPI<Contract>> {
  const response = await instance.post('/entity/contracts/read', { id });
  return {
    status: response.status,
    data: response.data,
  };
}

export async function vendorList(page: number, page_size: number): Promise<RespAPI<SuccessVendorList>> {
  const response = await instance.post('/entity/vendors/list', { page, page_size });
  return {
    status: response.status,
    data: response.data,
  };
}

export async function vendorCreate(req: CreateVendorRequest): Promise<RespAPI<SuccessVendorCreate>> {
  const response = await instance.post('/entity/vendors/create', req);
  return {
    status: response.status,
    data: response.data,
  };
}

export async function vendorUpdate(req: UpdateVendorRequest): Promise<RespAPI<null>> {
  const response = await instance.put('/entity/vendors/update', req);
  return {
    status: response.status,
    data: response.data,
  };
}

export async function chargebackUpdate(req: UpdateChargeback): Promise<RespAPI<null>> {
  const response = await instance.put('/nuvei/chargeback/update', req);
  return {
    status: response.status,
    data: response.data,
  };
}

export async function chargebackList(
  page: number,
  page_size: number,
  reqFilter: SearchChargebackReq
): Promise<RespAPI<ListChargebackResp>> {
  const response = await instance.post('/nuvei/chargeback/list', { page, page_size, ...reqFilter });

  return {
    status: response.status,
    data: response.data,
  };
}
