import { useCallback, useState } from 'react';

import { Button, Checkbox, Input, message, Modal, Select } from 'antd';

import { accountCreate } from '@src/clients/3thix';
import { CURRENCY_DESCRIPTIONS, RAIL_CURRENCY_MAP, RAIL_TYPES_MAP, roleTypes } from '@src/constants';
import { AccountInfo, AccountInfoBankUS, AccountRole, Error3thix } from '@src/types';

type Props = {
  entityID: string;
  toggleOpen: () => void;
  isOpen: boolean;
  onRefresh: () => void;
};

const ModalCreateAccount = ({ entityID, toggleOpen, isOpen, onRefresh }: Props) => {
  const [formData, setFormData] = useState({
    owner_entity_id: entityID,
    name: '',
    rail_type: '',
    rail: '',
    currency: '',
    info: {
      type: '',
      data: null,
    } as AccountInfo | null,
    roles: [] as AccountRole[],
  });

  const availableRails = RAIL_TYPES_MAP[formData.rail_type as keyof typeof RAIL_TYPES_MAP] || [];
  const availableCurrencies = RAIL_CURRENCY_MAP[formData.rail as keyof typeof RAIL_CURRENCY_MAP] || [];

  const handleChangeSelect = useCallback((value: string | boolean, fieldName: string) => {
    if (typeof value === 'string') {
      if (fieldName === 'rail_type') {
        const rails = RAIL_TYPES_MAP[value as keyof typeof RAIL_TYPES_MAP];
        const defaultRail = rails[0];
        const currencies = RAIL_CURRENCY_MAP[defaultRail as keyof typeof RAIL_CURRENCY_MAP];
        const defaultCurrency = currencies[0];
        setFormData((old) => ({
          ...old,
          rail_type: value,
          rail: defaultRail,
          currency: defaultCurrency,
        }));
      } else if (fieldName === 'rail') {
        const currencies = RAIL_CURRENCY_MAP[value as keyof typeof RAIL_CURRENCY_MAP];
        const defaultCurrency = currencies[0];
        setFormData((old) => ({
          ...old,
          rail: value,
          currency: defaultCurrency,
        }));
      } else if (fieldName === 'currency') {
        setFormData((old) => ({ ...old, currency: value }));
      }
    }
  }, []);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((old) => ({ ...old, [name]: value }));
  }, []);

  const handleRoleChange = useCallback((roleType: string, isChecked: boolean) => {
    setFormData((old) => {
      const roles = [...old.roles];
      if (isChecked) {
        roles.push({ type: roleType, is_default: false });
      } else {
        const index = roles.findIndex((role) => role.type === roleType);
        if (index !== -1) roles.splice(index, 1);
      }
      return { ...old, roles };
    });
  }, []);

  const handleDefaultChange = useCallback((roleType: string, isDefault: boolean) => {
    setFormData((old) => {
      const roles = old.roles.map((role) => (role.type === roleType ? { ...role, is_default: isDefault } : role));
      return { ...old, roles };
    });
  }, []);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (formData.rail_type !== 'BANK') {
        formData.info = null;
      }
      const { status, data } = await accountCreate(formData);

      if (status === 201) {
        onRefresh();
        toggleOpen();
        return;
      }

      message.error((data as Error3thix).message);
    },
    [formData, onRefresh, toggleOpen]
  );

  const handleInfoChangeBankUS = useCallback((field: string, value: string) => {
    setFormData((old) => {
      const info = old.info?.data as AccountInfoBankUS;
      return {
        ...old,
        info: {
          ...old.info,
          type: 'BANK_US',
          data: {
            ...info,
            [field]: value,
          },
        },
      };
    });
  }, []);

  return (
    <Modal
      open={isOpen}
      footer={null}
      title={
        <>
          <i className="fa-solid fa-user"></i> New Account
        </>
      }
      onOk={toggleOpen}
      onCancel={toggleOpen}
    >
      <div className="w-full mt-5">
        <form onSubmit={handleSubmit}>
          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="rail_type">
              Rail Type
            </label>
            <Select<string>
              size="large"
              value={formData.rail_type}
              className="my-2 w-full"
              onChange={(value) => handleChangeSelect(value, 'rail_type')}
            >
              {Object.keys(RAIL_TYPES_MAP).map((railType) => (
                <Select.Option key={railType} value={railType}>
                  {railType}
                </Select.Option>
              ))}
            </Select>
          </div>

          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="rail">
              Rail
            </label>
            <Select<string>
              size="large"
              value={formData.rail}
              className="my-2 w-full"
              onChange={(value) => handleChangeSelect(value, 'rail')}
            >
              {availableRails
                .filter((rail): rail is string => typeof rail === 'string')
                .map((rail) => (
                  <Select.Option key={rail} value={rail}>
                    {rail}
                  </Select.Option>
                ))}
            </Select>
          </div>

          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="currency">
              Currency
            </label>
            <Select
              size="large"
              value={formData.currency}
              className="my-2 w-full"
              onChange={(value) => handleChangeSelect(value, 'currency')}
            >
              {availableCurrencies
                .filter((currency): currency is string => typeof currency === 'string')
                .map((currency) => (
                  <Select.Option key={currency} value={currency}>
                    {CURRENCY_DESCRIPTIONS[currency]}
                  </Select.Option>
                ))}
            </Select>
          </div>

          {formData.rail_type === 'BANK' && formData.currency === 'USD' && (
            <>
              <div className="w-full">
                <label htmlFor="account_number">Account Number</label>
                <Input
                  required
                  size="large"
                  type="text"
                  name="account_number"
                  value={(formData.info?.data as AccountInfoBankUS)?.account_number || ''}
                  placeholder="Enter Account Number"
                  className="my-2 w-full"
                  onChange={(e) => handleInfoChangeBankUS('account_number', e.target.value)}
                />
              </div>
              <div className="w-full">
                <label htmlFor="routing_number">Routing Number</label>
                <Input
                  required
                  size="large"
                  type="text"
                  name="routing_number"
                  value={(formData.info?.data as AccountInfoBankUS)?.routing_number || ''}
                  placeholder="Enter Routing Number"
                  className="my-2 w-full"
                  onChange={(e) => handleInfoChangeBankUS('routing_number', e.target.value)}
                />
              </div>
            </>
          )}

          <div className="w-full">
            <label htmlFor="roles">Roles</label>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
              {roleTypes.map((roleType) => {
                const role = formData.roles.find((r) => r.type === roleType);
                return (
                  <div
                    key={roleType}
                    style={{
                      flex: '1 1 calc(50% - 10px)',
                      display: 'flex',
                      alignItems: 'center',
                      minWidth: '150px',
                    }}
                  >
                    <Checkbox checked={!!role} onChange={(e) => handleRoleChange(roleType, e.target.checked)}>
                      {roleType}
                    </Checkbox>
                    {role && (
                      <Checkbox
                        style={{ marginLeft: '10px' }}
                        checked={role.is_default}
                        onChange={(e) => handleDefaultChange(roleType, e.target.checked)}
                      >
                        Default
                      </Checkbox>
                    )}
                  </div>
                );
              })}
            </div>
          </div>

          <div className="w-full">
            <label htmlFor="name">Account Name</label>
            <Input
              required
              size="large"
              type="text"
              name="name"
              value={formData.name}
              placeholder="Account Name"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>

          <Button size="large" className="my-2" htmlType="submit" icon={<i className="fas fa-plus" />}>
            Create Account
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default ModalCreateAccount;
