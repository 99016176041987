import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, message, Skeleton, Table } from 'antd';
import Title from 'antd/es/typography/Title';

import { publisherList } from '@src/clients/3thix';
import Container from '@src/components/Container';
import { Error3thix, SuccessPublisherList } from '@src/types';

import ModalCreatePublisher from './components/ModalCreatePublisher';
// import ModalUpdatePublisher from './components/ModalUpdatePublisher';
import { COLUMNS, PAGE_SIZE } from './constants';

const Publishers = () => {
  const navigate = useNavigate();
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [page, setPage] = useState(1);
  const [publishersResp, setPublishersResp] = useState<SuccessPublisherList | null>(null);
  // const [selectedPublisher, setSelectedPublisher] = useState<Publisher | null>(null);
  // const [openUpdateModal, setOpenUpdateModal] = useState(false);

  const getPublishers = useCallback(async () => {
    const { data, status } = await publisherList(page, pageSize);
    if (status === 200) {
      setPublishersResp(data as SuccessPublisherList);
      setIsLoading(false);
      return;
    }

    message.error((data as Error3thix).message);
  }, [page, pageSize]);

  const toggleOpen = () => setOpenCreateModal((old) => !old);

  // const toggleUpdateModal = useCallback(() => {
  //   setOpenUpdateModal((prev) => !prev);
  // }, []);

  useEffect(() => {
    getPublishers();
  }, [getPublishers]);

  const rows = useMemo(() => {
    if (publishersResp === null) {
      return [];
    }

    return publishersResp.publishers;
  }, [publishersResp]);

  const onRow = useCallback(
    (record: { id: string }) => ({
      onClick: () => {
        navigate(`/publishers/${record.id}`);
      },
    }),
    [navigate]
  );

  // DO NOT ENABLE Edit publisher
  // const columns = useMemo(() => {
  //   return [
  //     {
  //       title: 'Actions',
  //       key: 'actions',
  //       render: (_: unknown, record: Publisher) => (
  //         <Flex align="flex-start" gap="small">
  //           <Tooltip title="Edit">
  //             <Button
  //               type="default"
  //               size="middle"
  //               icon={<i className="fas fa-edit" />}
  //               onClick={(event) => {
  //                 event.stopPropagation();
  //                 setSelectedPublisher(record);
  //                 toggleUpdateModal();
  //               }}
  //             />
  //           </Tooltip>
  //         </Flex>
  //       ),
  //     },
  //     ...COLUMNS,
  //   ];
  // }, [setSelectedPublisher, toggleUpdateModal]);
  const columns = COLUMNS;

  return (
    <Container>
      <div className="mb-[20px] flex justify-between">
        <div className="flex">
          <Title level={2}>
            <i className="fa-solid fa-building"></i> Publishers
          </Title>
        </div>
        <div className="flex">
          <Button type="default" size="large" icon={<i className="fas fa-plus" />} onClick={toggleOpen}>
            New Publisher
          </Button>
        </div>
      </div>
      <div>{isLoading && <Skeleton />}</div>
      <div>
        {!isLoading && publishersResp !== null && (
          <Table
            dataSource={rows}
            columns={columns}
            scroll={{ x: '50%' }}
            pagination={{
              position: ['bottomCenter'],
              defaultCurrent: page,
              total: publishersResp.total,
              defaultPageSize: pageSize,
              onShowSizeChange: (current, size) => {
                setPageSize(size);
              },
              onChange: setPage,
            }}
            onRow={onRow}
          />
        )}
      </div>

      <ModalCreatePublisher isOpen={openCreateModal} toggleOpen={toggleOpen} />

      {/* <ModalUpdatePublisher
        publisher={selectedPublisher}
        isOpen={openUpdateModal}
        toggleOpen={toggleUpdateModal}
        onRefresh={getPublishers}
      /> */}
    </Container>
  );
};

export default Publishers;
