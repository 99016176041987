import React from 'react';

import { ProcessorConfigConfig, ProcessorConfigConfigAdyen, ProcessorConfigConfigNuvei } from '@src/types';

export const COLUMNS = [
  { title: 'ID', dataIndex: 'id' },
  { title: 'Provider', dataIndex: 'processor_type' },
  {
    title: 'Processor Entity Email',
    dataIndex: 'processor_entity_email',
  },
  {
    title: 'Config',
    dataIndex: 'config',
    render: (config: ProcessorConfigConfig) => {
      if (!config) {
        return 'N/A';
      }
      if (config.type === 'NUVEI') {
        const merchant = config.data as ProcessorConfigConfigNuvei;
        return React.createElement(
          'div',
          {},
          React.createElement('div', {}, React.createElement('strong', {}, 'Merchant ID: '), merchant.merchant_id),
          React.createElement(
            'div',
            {},
            React.createElement('strong', {}, 'Merchant Site ID: '),
            merchant.merchant_site_id
          )
        );
      }
      if (config.type === 'ADYEN') {
        const merchant = config.data as ProcessorConfigConfigAdyen;
        return React.createElement(
          'div',
          {},
          React.createElement(
            'div',
            {},
            React.createElement('strong', {}, 'Routing Number: '),
            merchant.routing_number
          ),
          React.createElement(
            'div',
            {},
            React.createElement('strong', {}, 'Account Number: '),
            merchant.account_number
          ),
          React.createElement('div', {}, React.createElement('strong', {}, 'Phone Number: '), merchant.phone_number),
          React.createElement('div', {}, React.createElement('strong', {}, 'Web Address: '), merchant.web_address),
          React.createElement('div', {}, React.createElement('strong', {}, 'Address1: '), merchant.address1),
          React.createElement('div', {}, React.createElement('strong', {}, 'Address2: '), merchant.address2),
          React.createElement('div', {}, React.createElement('strong', {}, 'City: '), merchant.city),
          React.createElement(
            'div',
            {},
            React.createElement('strong', {}, 'State Province: '),
            merchant.state_province
          ),
          React.createElement('div', {}, React.createElement('strong', {}, 'Country Code: '), merchant.country_code),
          React.createElement('div', {}, React.createElement('strong', {}, 'Postal Code: '), merchant.postal_code)
        );
      }
    },
  },
  {
    title: 'Created at',
    dataIndex: 'created_at',
    render: (created_at: string) => {
      return new Date(created_at).toDateString();
    },
  },
  {
    title: 'Updated at',
    dataIndex: 'updated_at',
    render: (updated_at: string) => {
      return new Date(updated_at).toDateString();
    },
  },
];

export const PAGE_SIZE = 5;
