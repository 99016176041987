export const COLUMNS = [
  { title: 'Provider', dataIndex: 'provider' },
  {
    title: 'Enabled',
    dataIndex: 'enabled',
    render: (enabled: boolean) => (enabled ? 'Yes' : 'No'),
  },
  // TODO: Uncomment the following lines in case we need them
  // { title: 'MID', dataIndex: 'mid' },
  // { title: 'MCC', dataIndex: 'mcc' },
  // { title: 'CAID', dataIndex: 'caid' },
  // {
  //   title: 'Chargeback Fees',
  //   dataIndex: 'chargeback_fees',
  // },
  // {
  //   title: 'Tokenization Fees',
  //   dataIndex: 'tokenization_fees',
  // },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    render: (created_at: string) => (created_at ? new Date(created_at).toLocaleDateString() : 'N/A'),
  },
  {
    title: 'Updated At',
    dataIndex: 'updated_at',
    render: (updated_at: string) => (updated_at ? new Date(updated_at).toLocaleDateString() : 'N/A'),
  },
];

export const PAGE_SIZE = 5;
