import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Skeleton, Tabs } from 'antd';
import Title from 'antd/es/typography/Title';

import { gameRead, publisherRead } from '@src/clients/3thix';
import AccountsTable from '@src/components/AccountsTable';
import Container from '@src/components/Container';
import ContractsTable from '@src/components/ContractsTable';
import FeesTable from '@src/components/FeesTable';
import OrdersTable from '@src/components/OrdersTable';
import ProcessorConfigTable from '@src/components/ProcessorConfigTable';
import RolesTable from '@src/components/RolesTable';
import UserRolesTable from '@src/components/UserRolesTable';
import UsersTable from '@src/components/UsersTable';
import { Game as GameType, Publisher as PublisherType } from '@src/types';

import ApiKeysTable from './components/ApiKeysTable';
import Campaigns from './components/Campaigns';
import TransactionsLimits from './components/TransactionsLimits';

const Game = () => {
  const { id, publisherId } = useParams();
  const [publisherInfos, setPublisherInfos] = useState<PublisherType | null>(null);
  const [gameInfos, setGameInfos] = useState<GameType | null>(null);

  const getPublisher = useCallback(async () => {
    if (!publisherId) {
      return;
    }
    const { data, status } = await publisherRead(publisherId);

    if (status === 200) {
      setPublisherInfos(data as PublisherType);
      return;
    }
  }, [publisherId]);

  const getGame = useCallback(async () => {
    if (!id) {
      return;
    }
    const { data, status } = await gameRead(id);

    if (status === 200) {
      setGameInfos(data as GameType);
      return;
    }
  }, [id]);

  useEffect(() => {
    getPublisher();
    getGame();
  }, [getPublisher, getGame]);

  return (
    <Container
      breadcrumbs={[
        { name: 'Publishers', path: '/publishers' },
        { name: 'Publisher', path: `/publishers/${publisherId}` },
        { name: 'Game', path: `/publishers/${publisherId}/games/${id}` },
      ]}
    >
      <div className="mb-[10px]">
        {gameInfos ? (
          <Title level={2}>
            <i className="fa-solid fa-gamepad"></i> {gameInfos?.name}
          </Title>
        ) : (
          <Skeleton.Input />
        )}
      </div>
      <div className="mb-[20px]">
        {publisherInfos ? (
          <Title level={4}>{publisherInfos && publisherInfos.business_name}</Title>
        ) : (
          <Skeleton.Input />
        )}
      </div>

      <Tabs
        centered
        defaultActiveKey="1"
        items={[
          {
            label: 'Orders',
            key: '1',
            children: <OrdersTable issuerEntityID={id as string} />,
          },
          {
            label: 'Accounts',
            key: '2',
            children: <AccountsTable entityID={id as string} />,
          },
          {
            label: 'Fee Policies',
            key: '3',
            children: <FeesTable entityID={id as string} />,
          },
          {
            label: 'Processor config',
            key: '4',
            children: <ProcessorConfigTable parentEntityID={publisherId as string} entityID={id as string} />,
          },
          {
            label: 'Transactions limits',
            key: '5',
            children: <TransactionsLimits id={id as string} />,
          },
          {
            label: 'Campaigns',
            key: '6',
            children: <Campaigns id={id as string} />,
          },
          {
            label: 'Roles',
            key: '7',
            children: <RolesTable entityID={id as string} />,
          },
          {
            label: 'User Roles',
            key: '8',
            children: <UserRolesTable parentEntityID={id as string} entityID={null} />,
          },
          {
            label: 'Api Keys',
            key: '9',
            children: <ApiKeysTable gameId={id as string} />,
          },
          {
            label: 'Users',
            key: '10',
            children: <UsersTable entityID={id as string} />,
          },
          {
            label: 'Contracts',
            key: '11',
            children: <ContractsTable parentEntityID={publisherId as string} entityID={id as string} />,
          },
        ]}
      />
    </Container>
  );
};

export default Game;
