import { useCallback, useState } from 'react';

import { Button, message, Modal } from 'antd';

import { processorConfigRead } from '@src/clients/3thix';
import AdyenContractInfo from '@src/components/ProcessorConfigTable/components/ModalViewProcessorConfig/AdyenContractInfo';
import { AdyenExtra, ProcessorConfig, ReadProcessorConfigRequest, ReadProcessorConfigResponse } from '@src/types';

type Props = {
  processorConfig: ProcessorConfig;
  toggleOpen: () => void;
  isOpen: boolean;
  onRefresh: () => void;
};

const ModalViewProcessorConfig = ({ processorConfig, toggleOpen, isOpen, onRefresh }: Props) => {
  const [infoData, setInfoData] = useState<AdyenExtra | null>(null);
  const fetchContract = useCallback(async () => {
    const req: ReadProcessorConfigRequest = {
      provider: processorConfig.processor_type,
      id: processorConfig.id,
    };
    const { data, status } = await processorConfigRead(req);
    if (status === 200) {
      const info = (data as ReadProcessorConfigResponse).extra || null;
      setInfoData(info);
    } else {
      message.error('Failed to fetch contract data.');
    }
  }, [processorConfig]);

  return (
    <Modal
      open={isOpen}
      footer={null}
      width={700}
      title="View Processor Config Info"
      onCancel={() => {
        toggleOpen();
        onRefresh();
      }}
    >
      <div className="w-full mt-5">
        <Button size="large" className="mt-4 w-full" onClick={fetchContract}>
          Get Processor Config Info
        </Button>
        {processorConfig.processor_type === 'ADYEN' && <AdyenContractInfo infoData={infoData} />}
        {processorConfig.processor_type === 'NUVEI' && <div>NUVEI, There is no especial config</div>}
      </div>
    </Modal>
  );
};

export default ModalViewProcessorConfig;
