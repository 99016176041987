import { Button, Collapse, message, Tag, Typography } from 'antd';

import AdyenCapabilityInfo from '@src/components/ProcessorConfigTable/components/ModalViewProcessorConfig/AdyenCapabilityInfo';
import { AdyenAccountHolder, AdyenCapability, AdyenExtra } from '@src/types';

const { Title, Text } = Typography;
const { Panel } = Collapse;

type Props = {
  infoData: AdyenExtra | null;
};

const AdyenContractInfo = ({ infoData }: Props) => {
  if (!infoData) return null;

  const accountHolder: AdyenAccountHolder | undefined = infoData.account_holder;
  const onboardingURL: string | undefined = infoData?.onboard_link?.url;

  const handleCopyURL = () => {
    if (onboardingURL) {
      navigator.clipboard.writeText(onboardingURL);
      message.success('Onboarding link copied!');
    }
  };

  return (
    <div className="mt-6">
      {onboardingURL && (
        <div className="mb-4">
          <Text className="text-white">Onboarding Link:</Text>
          <br />
          <a href={onboardingURL} target="_blank" rel="noopener noreferrer" className="text-blue-500">
            {onboardingURL}
          </a>
          <div className="flex items-center gap-2">
            <Button size="large" className="mt-2" onClick={handleCopyURL}>
              Copy Link
            </Button>
          </div>
        </div>
      )}

      <Title level={4} className="text-white">
        Contract Information
      </Title>

      {accountHolder ? (
        <>
          <Text className="text-white">
            <b>Account Holder ID:</b> {accountHolder.id}
          </Text>
          <br />
          <Text className="text-white">
            <b>Description:</b> {accountHolder.description}
          </Text>
          <br />
          <Text className="text-white">
            <b>Status:</b>
            <Tag color={accountHolder.status === 'active' ? 'green' : 'red'}>{accountHolder.status}</Tag>
          </Text>

          <Title level={4} className="text-white mt-4">
            Capabilities
          </Title>
          <Collapse accordion defaultActiveKey={['1']} className="bg-gray-900 rounded-lg">
            {Object.entries(accountHolder.capabilities).map(([key, capability], index) => (
              <Panel key={index} header={key} className="text-white">
                <AdyenCapabilityInfo title={key} capability={capability as AdyenCapability} />
              </Panel>
            ))}
          </Collapse>
        </>
      ) : (
        <Text className="text-gray-400">No account holder information available.</Text>
      )}
    </div>
  );
};

export default AdyenContractInfo;
